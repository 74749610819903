
.header-ansatte{
    background:burlywood;
    background-image:
                linear-gradient(45deg,
                    rgba(222, 184, 135,1) 60%,
                    rgb(255,255,255,0.5) 1%,
                    rgba(200, 200, 200, 0.4) 39%), 
                    url(
                    "../../imgs/construction_background.jpg");
    background-size: cover;
    background-position: 50% 30%;
    font-weight:700;
    text-decoration: underline;
    padding:1.5em;
    letter-spacing: 0.05em;
    display: flex; 
    margin-top:0;
}

.employees-container{
    margin:2em;
    margin-left:2em;
    margin-right:2em;
    min-height: 50vh;
}

.employee-item{
    background: rgba(222, 184, 135,0.4);
    margin: 0.5em;
    padding: 0.9em 0.2em;
    border-radius: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.employee-item.visibleEmployee{
    opacity: 1;
}

.employee-img{
    margin: 0px;
    margin-left:10px;
    border: 1px solid white;
    width: 35%; /* Set the width to 100% to fill the container */
    height: auto; 
    max-height:200px;
    object-fit: cover; /* Ensure the image covers the container */
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.employee-text{
    margin-left: 15px;
    margin-right:10px;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.employee-text h3{
    border-bottom: burlywood 2px solid;
    word-break: break-word; /* Ensure long words break to avoid overflow */
    text-align: left;
}

.employee-sub-text p{
    word-break: break-word; /* Ensure long words break to avoid overflow */
    text-align: left;
}


.employee-phone-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0em;
    margin-left:10px;
    width:100%;
    margin-top:-10px;
}

.employee-phone-container a{
    text-decoration:none;
    color:black;
}

.employee-phone-container img{
    margin-right: 1em;
}

.phone-icon{
    margin-right: 0.5em;
}

.title-region-text{
    margin-top:0px;
    font-weight: 300;
}

.employee-sub-text{
    color:#333333;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    
}


@media (min-width: 1001px) {
    .employees-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em;
        margin-left:15%;
        margin-right:15%;
      }


  }

  @media (min-width: 600px) {
    .header-ansatte{
        background-image:
        linear-gradient(45deg,
            rgba(222, 184, 135,1) 40%,
            rgb(255,255,255,0.5) 1%,
            rgba(174, 179, 185, 0.418) 59%), 
            url(
            "../../imgs/construction_background.jpg");
    }
  }

  @media (max-width: 420px) {
    .employees-container{
        margin:0.5em;
    }
  }