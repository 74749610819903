.object-div{
    background:lightgray;
    padding:0.3em;
    margin:0.5em;
    display:flex;
    flex-direction: column;
}

.object-info-div-archive{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.main-container-ads{
    display: flex;
}

.filter-container{
    width:20%;
    min-width:100px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.ads-container-archive{
    width:80%;
    min-height: 400px;
}

.object-status{
    background:rgb(146, 181, 216);
    padding:0.2em;
    border-radius:0.2em;
}

.object-title:hover{
    cursor: pointer;
}

.object-status:hover{
    cursor: pointer;
}

.status-options{
    background:rgb(226, 220, 220);
    padding:0.2em;
}

.status-info{
    background:rgb(226, 220, 220);
    padding:0.2em;
    display: flex;
}

.generell-info-archive{
    width:60%;
}

.status-option{
    margin:0.2em;
}

.status-option:hover{
    cursor: pointer;
    background:burlywood;
}

.more-info-btn{
    background:burlywood;
    width:30%;
    margin-left:35%;
    border-radius:0.5em;
    font-weight: 300;
}

.more-info-btn:hover{
    cursor: pointer;
    letter-spacing: 0.03em;
}

.konfidensiell-info-archive{
    background:burlywood;
    width:50%;
}

.konfideensiell-info-titel{
    color:black;
    text-decoration: underline;
    font-weight: 600;
}