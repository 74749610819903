.footer{
    background: #889999;
    margin: 0;
    padding: 1.5em;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}

.footer-top-container{
    display:flex;
    flex-direction: row;
}

.footer-top-logo-container{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-top-logo-container p{
    display: flex;
    max-width:150px;
    opacity: 0.7;
    margin-top:0;
}

.footer-top-container-info{
    width:20%;
    justify-content: flex-start;
}

.footer-top-container-info h3{
    display: flex;
    letter-spacing: 0.05em;
    width: fit-content;
    border-bottom: burlywood 2px solid;
    font-weight: 500;
}

.footer-bot-container{
    border-top:1px solid black;
    display:flex;
    justify-content: flex-end;
    margin-top:1em;
    margin-bottom:0;
}

.footer-bottom-info-container{
    display: flex;
}

.footer-info{
    display: flex;
    flex-direction: column;
    font-weight:300;
}

.footer-label{
    display: flex;
    align-self: flex-start;
    opacity: 0.7;
}

.footer-value{
    display: flex;
    align-self: flex-start;
    margin-left:1em;
    font-weight: 600;
}

.footer h3{
    margin-bottom:0.9rem;
    margin-top: 0.5rem;
}

.footer-info p {
    margin-top:0.1rem;
    margin-bottom:0.1rem;
}

.footer-icons{
    margin:0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}
.footer-icons img{
    height:25px;
    transition: 100ms;
    margin-left:0;
    margin-right:2em;
}

.footer-icons img:hover{
    cursor: pointer;
    height:30px;
}

.footer-logo{
    width:60%;
    max-width:150px;
}

@media (max-width: 600px) {
    .footer-top-container{
        flex-direction: column;
        align-items:center;
    }
    .footer-top-container-info{
        width:40%;
        margin-bottom: 1.5em;
    }
    .footer-top-logo-container{
        width:40%;
    }
}
