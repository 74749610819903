.info-container{
    margin-left:10%;
    margin-right:10%;
}

.header-omoss{
    background:burlywood;
    background-image:
                linear-gradient(45deg,
                    rgba(222, 184, 135,1) 60%,
                    rgb(255,255,255,0.5) 1%,
                    rgba(200, 200, 200, 0.4) 39%), 
                    url(
                    "../../imgs/construction_background.jpg");
    background-size: cover;
    background-position: 50% 30%;
    font-weight:700;
    text-decoration: underline;
    padding:1.5em;
    letter-spacing: 0.05em;
    display:flex;
    margin-top:0;
}

.top-info-container{
    padding:0.5em;
}

.info-img{
    object-fit: cover;
    width:60%;
    height:250px;
    border-radius: 1em;
    margin:0.5em;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.info-box{
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-right: -10%;
    margin-top:1em;
    padding:1.5em;
    margin-bottom:1.5em;
    border-radius: 1.5em;
    background: rgb(212, 204, 194);
    font-weight:300;
  }

.info-box-2{
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-left:-10%;
    padding:1.5em;
    margin-bottom:1.5em;
    border-radius: 1.5em;
    background: rgb(212, 204, 194);
    font-weight:300;
}

.info-box p {
    width:80%;
    margin-left: 10%;
}

.info-box-2 p {
    width:80%;
    margin-left: 10%;
}

.info-box-2-last{
    margin-left:-50px;
    padding:0.5em;
    padding-left: 2em;
    margin-bottom: 2em;
    border-radius: 1.5em;
    background: rgb(212, 204, 194);
    font-weight:300;
}

.main-container{
    background: none;
}
.info-box-header{
    margin-bottom:1em;
}

.hidden {
    display: none;
  }
  
.visible {
    display: block;
    /* Add other styling as needed */
  }

.open-aktsomhet{
    margin-left:auto;
    margin-right:auto;
    border-bottom: 2px solid burlywood;
    width:fit-content;
    display:none;
}
.open-aktsomhet:hover{
    cursor: pointer;
}

.aktsomhet{
    display:flex;
    flex-direction: column;
    align-items: center;
    position:fixed;
    background:burlywood;
    top:5%;
    left:5%;
    width:80%;
    padding: 5%;
    border-radius:1em;
    box-shadow: 10px 10px;
    height:75vh;
    overflow-y: scroll;
}

.close-aktsomhet{
    font-weight:800;
    border-bottom:2px solid black;
}

  @media (max-width: 1001px) {
      .info-box{
          flex-direction: column;
      }
      .info-box p {
        margin-left:0;
        width:100%;
      } 
      .info-box-2 p{
        margin-left:0;
        width:100%; 
      }
      .info-box-2{
          flex-direction: column-reverse;
      }
      .info-img{
          height: 180px;
      }
  }

/*
@media (min-width: 1001px) {
    .info-box{
        margin-left: 350px;
    }
    .info-box-2{
        margin-right:350px;
    }
    .info-box-2-last{
        margin-right:350px;
    }
  }
  */
  @media (min-width: 600px) {
    .header-omoss{
        background-image:
        linear-gradient(45deg,
            rgba(222, 184, 135,1) 40%,
            rgb(255,255,255,0.5) 1%,
            rgba(174, 179, 185, 0.418) 59%), 
            url(
            "../../imgs/construction_background.jpg");
    }
  }