.Splash-text {
    letter-spacing: 0.1em;
    background: black;
    padding: 0.2em;
}

nav{
  opacity: 1;
  position: fixed;
  z-index: 9999;
}

.header-text-splash {
  margin-top: -18em;
  margin-bottom: 5em;
  font-weight: 800;
  max-width:80%;
  
}

.is-menu-open .header-text-splash {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0.8) rotate(-45deg);
}

@keyframes fadeInUp {
  0% {
    transform: translateY(40%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

.header-text-splash {
  animation: 1.5s fadeInUp;
}

/* Keyframes animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8) rotate(-45deg);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
}

.Header-text{
    background: black;
    padding: 0.2em;  
    letter-spacing: 0.1em;
}
.search-field{
    /* this class is for input field, if in your code its different, just substitute the class name '.search-field' */
        padding: 10px 15px;
        min-width: 55%;
        font-size: 1rem;
        line-height: 1.5em;
        color: #373D41;
        background-color: #FFF;
        background-clip: padding-box;
        border: 1px solid #C5C5C7;
        border-radius: 6px;
        -webkit-box-shadow: none;
        box-shadow: none;
        flex-grow: 1;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
.search-submit{
    /* this class is for submit button, if in your code its different, just substitute the class name '.search-submit' */
        background-color:#1a1a1a;
        color: #ffffff;
        font-weight: 700;
        text-decoration: none;
        white-space: normal;
        -webkit-transition: none;
        transition: none;
        cursor: pointer;
        outline: 0;
        padding: 14px 24px 13px;
        font-size: 1rem;
        line-height: 1.25rem;
        border-radius: 6px;
        border-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
.search-form .input-group{
      text-align:center;
    }

.search-box-suggestions{
  background:rgb(0, 0, 0,0.9);
  width:50%;
  margin-left:22%;
  padding:3%;
  padding-bottom:0%;
  padding-top:0em;
  margin-top:0.2em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  position:absolute;
}

.search-box-suggestion{
  padding-top:0.02em;
  padding-bottom:0.02em;
  width:112%;
  margin-left:-6%;
  min-height:30px;
  background: transparent;
  transition: background 0.5s ease;
}

.search-box-suggestion:hover{
  background:rgb(70, 70, 70);
  cursor: pointer;
}

.search-box-link{
  text-decoration: none;
  color:white;
}

.search-box-suggestion-county{
  font-weight: 200;
  margin-top:-1em;
}

.main-item{
    letter-spacing: 0.1em;
    background: burlywood;
    padding: 1.4em;
    margin: 1.1em;
    margin-bottom: 1.5em;
    border-radius: 1em;
    font-size: 1.1em;
    color:black;
    transition: all 0.3s ease;
}

.main-item:hover{
    font-size: large;
    background: #EEDAC1;
    letter-spacing: 0.2em;
    cursor: pointer;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}
.sell-main-link{
    text-decoration: none;
}
.main-sell{
    background: gray;
    padding: 2em;
    margin: 0.9em;
    border-radius: 1em;  
    color: whitesmoke;  
    text-decoration: none;
    transition: all 0.3s ease;
}

.main-sell:hover{
    font-size: large;
    opacity: 0.9;
    letter-spacing: 0.01em;
    cursor: pointer;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.menuItem {
    display: block;
    margin: 2rem 4rem;
    font-size: 1.8rem;
    color: white;
    text-decoration: none;
  }
  
.menuItem:hover {
    text-decoration: underline;
  }

.menuItem-login{
    display: block;
    margin: 0.5rem 4rem;
    font-size: 1.8rem;
    color: white;
    text-decoration: none;
    background: green;
    padding: 0.4em;
    border-radius: 0.5em;
    max-width:50%;
    margin-left:auto;
    margin-right:auto;
  }
.menuItem-login:hover{
    text-decoration: underline;
    opacity: 0.95;
  }

.admin-menu-header{
    margin-bottom: 0.1em;
    margin-top:0em;
    letter-spacing: 0.1em;
    text-decoration: underline;
}

.admin-menuItem{
    display: block;
    margin: 0.5rem 4rem;
    text-decoration: none;
    color: white;
}
.admin-menuItem:hover{
    text-decoration: underline;
}

.user-id-header{
    position: fixed;
    top: -0.3rem;
    right: 0.8rem;
    font-size: 0.5em;
    background: rgba(0, 0, 0, 0.5);
    padding:0.2em;
    border-radius: 0.3em;
}
  
.hamburger {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: black solid 1px;
    background: white;
    cursor: pointer;
  }



.closeIcon {
    display: none;
  }
  

.menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: black;
    color: white;
    list-style: none;
    padding-top: 2rem;
    opacity: 0;
  }
  
.showMenu {
    transform: translateY(0);
    transition: transform 0.2s;
    opacity: 0.95;
  }

.icon-item{
    max-width: 4em;
}

.omOss-text{
    margin: auto;
    margin-bottom:2em;
    max-width: 70%;
    padding: 1em;
    line-height: 150%;
    min-height:50vh;
    background:lightgray;
    border-radius:2em;
}

.logIn-form{
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.logIn-label{
    padding: 0.3em;
}

.logIn-input{
  width: 80%;
  padding:0.5em;
  border-radius:0.7em;
  border:1px solid black;
}

.login-full-form{
  background: burlywood;
  border-radius: 2em;
  width:60%;
  max-width:600px;
  margin-left:auto;
  margin-right:auto;
  padding-top:1em;
  margin-bottom:2em;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.logIn-btn{
  align-self: center;
  background: rgb(37, 211, 37);
  border-radius: 999px;
  box-shadow: rgb(14, 80, 14) 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  margin-bottom:0em;
}

.logIn-btn:hover{
    opacity: 0.8;
    cursor: pointer;
}

.language-btn{
    position: fixed;
    top: 2em;
    right:4em;
    background: none;
    border: none;
    display: flex;
    border-radius: 0.4em;
}
.language-btn:hover{
    background: lightgray;
    cursor: pointer;
}
.logo-item{
    position:fixed;
    width:70px;
    top:0em;
    left:0em;
    padding-top:0.5em;
}

.logo-item-wrapper{
  position:fixed;
  top:0em;
  left:0em;
  padding: 30px;
  padding-right: 100%;
  background:#ffffff;
}

.globe-icon-item{
    width: 20px;
    margin: 0.2em;
}

.flagg-icon-item{
    width: 15px;
    border-radius: 0.3em;
    margin-top: 15%;
}

.language-dropdown {
    position: fixed;
    top: calc(50px + 0.5em); /* Position below the button */
    right: 0px;
    background: burlywood;
    padding: 0.5em 0;
    width: 120px; /* Adjust the width as needed */
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
    z-index: 1; /* Ensure the dropdown appears above other elements */
  }

.language-option {
    width:100%;
    letter-spacing: 0.1em;
    display: flex;
    padding: 0.5em;
    padding-top: 0em;
    padding-bottom: 0em;
    border: none;
    background: none;
    cursor: pointer;
    transition: opacity 0.3s;
  }

.language-option:hover{
    opacity: 0.5;
    background:rgb(228, 201, 166);

}

.language-dropdown-text{
    margin-left: 1em;
}

.login-option{
    text-decoration: none;
    color: black;
}

.login-option:hover{
    cursor: pointer;
    opacity: 0.7;
    text-decoration: underline;
}

.login-error{
    color: red;
    visibility: hidden;
}

.showpwd{
    font-size: 0.6em;
}

.menuIcon,
.closeIcon {
  font-family: 'Material Icons';
  font-size: 24px;
  line-height: 24px;
  color: #000;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.closeIcon {
  display: none;
}

.hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .hamburger:focus {
    outline: none;
  }
  
  .hamburger .menuIcon,
  .hamburger .closeIcon {
    position: relative;
    width: 24px;
    height: 2px;
    background-color:lightblue;
    transition: transform 0.3s ease;
  }
  
  .hamburger .menuIcon::before,
  .hamburger .menuIcon::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: lightblue;
    transition: transform 0.3s ease;
  }
  
  .hamburger .menuIcon::before {
    top: 6px;
  }
  
  .hamburger .menuIcon::after {
    top: 6px;
  }
  
  .hamburger.open .menuIcon {
    transform: rotate(45deg);
  }
  
  .hamburger.open .menuIcon::before {
    transform: rotate(-90deg) translate(6px, -12px);
  }
  
  .hamburger.open .menuIcon::after {
    opacity: 0;
  }



  @media (min-width: 1001px) {
    .front-main-container{
      display:flex;
      flex-direction: row;
      justify-content: space-around;
      height: 50vh;
      margin-top:3em;
      margin-left: 2em;
      margin-right: 2em;
    }
    .sell-main-link{
      width:45%;
    }
    .main-item{
      height: 200px;
    }
  }


