.booking-choice{
    margin:2em;
    padding:2em;
    background:lightgray;
    border-radius:0.5em;
    width:35%;
    margin-left:auto;
    margin-right:auto;
}

.booking-choice-container{
    display: flex;
    flex-direction: row;
    min-height:100px;
    margin-bottom:20px;
    margin-top:20px;
}

.booking-choice:hover{
    letter-spacing: 0.01em;
    cursor: pointer;
}

.booking-container{
    background: rgba(222, 184, 135,0.2);
    width:90%;
    margin-right: auto;
    margin-left:auto;
    margin-bottom:2em;
    padding:1em;
    border-radius:2em;
}

.new-booking-container{
    display: flex;
    flex-direction: column;
    width:80%;
    max-width:300px;
    margin-right:auto;
    margin-left:auto;
}

.new-booking-element{
    width: 100%;
    margin: 0.5em;
    padding:0.5em;
    border: 1px solid black;
    border-radius: 0.5em;
}

.new-booking-element-scroll{
    width: 105%;
    margin: 0.5em;
    padding:0.5em;
    border: 1px solid black;
    border-radius: 0.5em;   
}
.new-booking-element-free-text{
    width: 100%;
    margin: 0.5em;
    padding:0.5em;
    resize: none;
    height:100px;
    border-radius: 0.6em;
}

.new-booking-submit-btn{
    align-self: center;
    background: rgb(37, 211, 37);
    border-radius: 999px;
    box-shadow: rgb(14, 80, 14) 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    margin:1em;
  }

.all-booking-element-scroll{
    width: 50%;
    margin: 0.5em;
    padding:0.5em;
    border: 1px solid black;
    border-radius: 0.5em;   
}

.calanderinfo-container{
    padding:1em;
}

.booking-element{
    background: linear-gradient(rgba(222,184,135,0.7),rgba(222,184,135,0.3));
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0.4em;
    padding-right: 0.4em;
}

.booking-element p {
    font-size: 0.8em;
    width:20%;
}

.booking-status-filter{
    padding:0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.booking-status-filter div {
    padding:0.3em;
}

.booking-element:hover{
    background: rgba(222, 184, 135,0.9);
    cursor: pointer;
}

.additional-info{
    margin-top:0em;
    margin:0;
    background:lightgrey;
    padding:1em;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}

.additional-info-sub-container{
    display:flex;
    flex-direction: row;
}

.booking-comments{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.name-org-phone-container{
    min-width:30%;
    margin-right:2em;
    background:burlywood;
    padding:1em;
    border-radius:1em;
    height:fit-content;
}

.edit-btn{
    background:rgb(145, 211, 145);
    padding:0.3em;
    height:1.2em;
    border-radius: 0.4em;
    width:30%;
    max-width:200px;
    margin-left:65%;
}

.edit-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.booking-phone-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.booking-status-headerline{
    justify-self: flex-start;
    background:grey;
    padding:0.5em;
    border-radius:0.5em;
    max-width:5px;
    max-height:5px;
}

.booking-status-container{
    display:flex;
    flex-direction: row;
    margin-bottom:-1em;
    align-items: center;
}

.booking-status-container p{
    margin:0.5em;
    padding:0.4em;
    font-weight:500;
    border-radius: 0.2em;
}

.booking-status-container p:hover{
    cursor: pointer;
    opacity: 0.8;
}

.status-not-contact-btn{
    background:rgba(128, 128, 128, 0.20);
    border: 2px solid grey;
}

.status-contact-btn{
    background:rgba(255, 255, 0, 0.2);
    border: 2px solid  rgb(255,255,0);
}

.status-visit-btn{
    background:rgb(126, 126, 240,0.2);
    border: 2px solid  rgb(126, 126, 240);
}

.status-sale-btn{
    background:rgb(50, 178, 50,0.2);
    border: 2px solid  rgb(50, 178, 50);
}

.status-not-sale-btn{
    background:rgb(255, 100, 100,0.2);
    border: 2px solid  rgb(255, 100, 100);
}

.status-sold-btn{
    background:rgb(100, 255, 100,0.2);
    border: 2px solid  rgb(100, 255, 100);
}

.status-wait-btn{
    background:rgb(138, 243, 243,0.2);
    border: 2px solid  rgb(138, 243, 243);
}

.status-tilbud-btn{
    background:rgba(225, 42, 231, 0.2);
    border: 2px solid  rgb(210, 110, 235);
}

.modal-edit{
    position: absolute;
    background:#E4D9C3;
    padding:0.8em;
    border:1px solid black;
    border-radius:0.4em;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.modal-edit-element-container{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.modal-edit-element-container-text{
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.edit-modal-submit-btn{
    color:white;
    background:rgb(50, 143, 50);
    padding:0.2em;
    border-radius:0.2em;
}

.edit-modal-submit-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.modal-close-btn{
    color:white;
    border:1px solid white;
    border-radius:0.5em;
    padding:0.5em;
    background:rgb(85, 71, 71);  
}

.modal-close-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}
.modal-background{
    position:fixed;
    left:0;
    top:0;
    padding-left:15%;
    padding-top: 5%;
    width: 100vw;
    height:100vh;
    background: rgba(0,0,0,0.5);
}

.fetch-old-btn{
    background:lightgray;
    border-radius:0.2em;
    padding:0.2em;
    width:fit-content;
}

.fetch-old-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 500px) {

    .booking-status-filter{
        display:grid;
        grid-template-columns: repeat(2,50%);
        justify-items:start;
    }

    .calanderinfo-container{
        padding:0em;
    }

    .booking-element p{
        font-size:0.7em;
        width:100%;
    }

    .booking-element-address{
        grid-column: span 2;
    }

    .booking-element{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .additional-info-sub-container{
        flex-direction: column;
        font-size:0.8em;
    }
    .name-org-phone-container{
        min-width:30%;
        margin-right:0.5em;
    }
    .name-org-phone-container p {
        font-size:0.8em;
    }

    .booking-status-container{
        display: grid;
        grid-template-columns: repeat(3,33%);
    }

    .booking-status-container p{
        margin:3px;
    }

    .edit-btn{
        height:auto;
        margin-top:25px;
    }

    .modal-edit{
        max-width:80%;
    }

    .booking-choice{
        padding:1em;
    }
    
  }