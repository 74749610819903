.favorite-result-container{
  padding:1em;
  min-height: 50vh;
}
.favorite-result-container .favorite-result{
  display:flex;
  flex-direction: row;
  max-height:40vh;
  margin:1em;
}

.favorite-info-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding:0.1em;
  width:60%;
}

.favorite-sub-info-container{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.favorite-result{
    background: burlywood;
    padding:1em;
    margin:1em;
    border-radius:1em;
    justify-content: space-between;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease;
    min-height:150px;
}

.favorite-result img{
  width:40%;
}

.favorite-result p{
  margin:0.1em;
}

.favorite-result:hover{
  background: rgb(199, 158, 105);
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

h3{
    margin-bottom: 0em;
}

.obj-title{
  font-size: 0.9em;
}

.img-for-sale-favorite{
  border-radius: 0.3em;
  width: 30%;
  height: auto;
  margin-left: 0em;
  margin-right:0.5em;
  object-fit:cover;
}


.img-for-sale-favorite{
  border-radius: 2em;
  width: 120px;
  height: auto;
  margin-left: 0em;
  margin-right:0.2em;
}

.ingen-treff{
  margin-top:5em;
  height:50vh;
}