.render-first{
    margin-top:1em;
}

.regnr-search{
    background:burlywood;
    display:flex;
    justify-content: space-between;
    display:none;
}

#lengde{
    display:none;
}

#bredde{
    display:none;
}

.regnr-search p {
    background:lightgreen;
    padding:0.2em;
    margin-right:0.5em;
    border-radius: 0.2em;
}

.regnr-search p:hover{
    cursor: pointer;
    opacity: 0.8;
}

.km-brukstimer-selector-container{
    display: flex;
    flex-direction: row;
}
.km-brukstimer-selector{
    margin-top:0.5em;
    margin-bottom:0.5em;
}

.camera-preview{
    width:90%;
    margin-top:-5em;
}

.upload-image{
    margin:0.5em;
}

.upload_and_sample_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background:lightgray;
}

.save-without-images{
    font-weight:800;
    background:burlywood;
    padding:0.5em;
    margin:1em;
    margin-top:2em;
    border:1px solid black;
    border-radius: 0.5em;
    width:100%;
}

.save-without-images:hover{
    cursor: pointer;
    opacity: 0.7;
}

.sample-img{
    width:120px;
    margin:0.5em;
}

.captured-image{
    width:90%;
    margin-top:1em;
}

.next-img-btn{
    margin:2em;
    padding:1em 2em;
    background:green;
    border: 1px solid white;
    border-radius: 2em;
    color:white;
}

.next-img-btn:hover{
    cursor: pointer;
    opacity: 0.7;
}

.retake-img-btn{
    margin:2em;
    padding:1em 2em;
    background:red;
    border: 1px solid white;
    border-radius: 2em;
    color:white;  
}

.retake-img-btn:hover{
    cursor: pointer;
    opacity: 0.7;
}

.add-utils-container{
    display: flex;
    justify-content: space-between;
    margin:0.2em;
}

.add-util{
    background:burlywood;
    padding:0.5em;
    border-radius: 0.5em;
}

.add-util:hover{
    cursor: pointer;
    opacity: 0.7;
}

.utils{
    background:lightgrey;
    font-size: 0.6em;
}
.back-btn{
    margin-right:70%;
    margin-bottom:10%;
    padding:0.5em;
    border:1px solid black;
    border-radius:0.5em;
    background:blue;
    color:white;
}

.back-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.category-select-container{
    display:flex;
    flex-direction: column;
    width:100%;
    padding:0.5rem;
    margin-left:-0.2em;
}
.category-select-container select{
    padding:0.5rem;
    margin:2px;
    border-radius: 0.5em;
}

.done-btn{
    margin-top:-4em;
    color:white;
    width:30%;
    margin-left:60%;
    padding:0.4em;
    background:rgb(83, 83, 22);
    border-radius:1em;
}