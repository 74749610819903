.search-field{
    /* this class is for input field, if in your code its different, just substitute the class name '.search-field' */
        padding: 8px 13px;
        min-width: 35%;
        max-width:40%;
        font-size: 1rem;
        line-height: 1.5em;
        color: #373D41;
        background-color: #FFF;
        background-clip: padding-box;
        border: 1px solid #C5C5C7;
        border-radius: 6px;
        -webkit-box-shadow: none;
        box-shadow: none;
        flex-grow: 1;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
.search-submit-myads{
    /* this class is for submit button, if in your code its different, just substitute the class name '.search-submit' */
        background-color:#1a1a1a;
        color: #ffffff;
        font-weight: 700;
        text-decoration: none;
        white-space: normal;
        -webkit-transition: none;
        transition: none;
        cursor: pointer;
        outline: 0;
        padding: 12px 22px 11px;
        font-size: 1rem;
        line-height: 1.25rem;
        border-radius: 6px;
        border-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
.search-form .input-group{
      text-align:center;
    }

.search-form{
    margin:0.1em;
    max-height: 25vh;
}

.header{
    background:burlywood;
    padding:1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 0em;
    display: flex;
    justify-content: space-between;
    margin-top:25px;
}

.my-ads-container{
    min-height:55vh;
}

.my-ad-element{
    background:lightgrey;
    margin:1em;
    padding:0.1em;
}

.my-ad-link{
    text-decoration: none;
    color:black;
    border-bottom: burlywood;
}

.my-ad-link:hover{
    border-bottom: burlywood;
    cursor: pointer;
}

.my-ads-info-element-container{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.my-ads-info-element{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.my-ads-info-element img{
    height:1em;
    margin-right:1em;
}