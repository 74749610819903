.info-box,
.info-box-2 {
  opacity: 0;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* Add this class when the element is in view */
.info-box.in-view,
.info-box-2.in-view {
  opacity: 1;
}