.header{
    background:burlywood;
    padding:0.5em;
    padding-left:0.9em;
    letter-spacing: 0.1em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    margin-top:25px;
}

.image-and-main-info-container{
    display: flex;
    margin-bottom:2em;
}

.image-and-main-info-container .image-carousel{
    width:70%;
    margin-right:3em;
}

.image-and-main-info-container div .main-info{
    
    margin:0;
    padding:0;
    display:flex;
    justify-self: flex-end;
}

.back-link{
    width:fit-content;
    height:fit-content;
    display:flex;
    align-items: center;
    margin:0.2em;
    margin-left:0.5em;
    padding:0.3em;
    border-radius: 0.1em;
    text-decoration:none;
    color:black;
    background:lightgrey;
    font-size: 10px;
    margin-top:0;
}

.back-link img{
    display:flex;
    height:10px;
}

.back-link:hover{
    cursor: pointer;
    opacity:0.8;
}

.back-links-chained{
    display: flex;
    margin:0;
    padding:0;
    margin-left:0.5em;
    margin-bottom:0.3em;
}
.back-links-chained p{
    margin:0;
    padding:0;
    font-size: 13px;
    font-weight: 800;
    letter-spacing:0.08em;
    color:black;
}
.back-links-chained p:hover{
    cursor:pointer;
    color:grey;
}

.ref-dato-container{
    margin-top:10px;
    padding-top:0em;
    height:100%;
}

.object-status-title{
    margin-left:0.5em;
    font-weight: 500;
}

.link-term-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.price-container{
    font-size: 2em;
    margin-bottom: 0.3em;
    margin-top:1em;
    display:flex;
    justify-content: flex-start; 
}

.place-container{
    margin-top: 0em;
    margin-bottom:0em;
    font-style: italic;
    color:#333333;
    display:flex;
    justify-content: flex-start;
    padding-left:10px;
}

.free.text{
    align-items: flex-start;
    justify-content: flex-start;

}

.free-text-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    min-height: 100px;
    margin-left:2em;
    margin-right:0;
}

.free-text-container div{
    margin-right:2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.bullet-ball{
    background:burlywood;
    color:burlywood;
    margin-right:0.5em;
    border-radius: 50%;
    display: inline-block;
    width: 10px; /* Adjust as needed */
    height: 10px;
    color:white;
}

.info-dropdown-container{
    width:100%;
    margin-bottom:1em;
    max-width:350px;
}

.info-dropdown-element h3{
    font-size:1em;
    margin-bottom:0.2em;
}


.info-dropdown-element h3:hover{
    cursor: pointer;
    color:burlywood;
    transform:scale(1.05)
}

.info-dropdown-element{
    width:100%;
    border-bottom: 1px solid burlywood;
}

.info-dropdown-list{
    width:100%;
}

.info-dropdown-list h4{
    font-weight:500;
    margin-bottom: 0;
    margin-top:0.2em;
    border-bottom: 1px solid grey;
}

.info-dropdown-list p{
    margin-top: 0;
    font-weight:600;
}

.back-link-image{
    max-width:20px;
}

.county-map{
    margin: 1em;
}
.location-container{
    margin: 1em;
    margin-top: 1em;
}

.object-information-container{
    width:100%;
}

.object-image{
    border-radius: 1em;
    width:93%;
    max-width: 87vw;
    height:55vh;
    object-fit: contain;
}

.full-icon{
    width:30px;
}
.general-info-container-top{
    margin-top:2em;
    display:flex;
    background: lightgrey;
    justify-content: space-around;
    flex-direction: column;
    border-radius:1em;
}

.general-info-container-top{
    width:95%;
    align-items: flex-start;
    padding:5%;
}

.general-info-container-top div p{
    width:fit-content;
    margin-top:0.5em;
    margin-bottom:0.5em;
}


.general-info-container-top div{
    width:100%;
}


.general-info-container{
    margin-top:0.2em;
    margin-bottom:1.5em;
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-template-rows: repeat(2, minmax(25%, 1fr));
    gap:10px;
    background: lightgrey;
    justify-content: space-around;
    font-weight: bold;
}

.general-info-item{
    font-weight: bolder;
}

.mva-span{
    font-size:20px;
    margin-top:0;
    display:flex;
    align-items: flex-start;
    margin-left:1em;
}

.finans-container{
    background: lightskyblue;
    padding: 0.7em;
    padding-top:0.1em;
    padding-bottom:0.1em;
    border-radius:1em;
    display: none;
}

.finans-header{
    margin-top:0.4em;
    margin-bottom:0.2em;
}

.message-btn{
  background: rgb(37, 211, 37);
  border-radius: 999px;
  box-shadow: rgb(14, 80, 14) 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.link-container{
    padding:1em;
    margin-top:2em;
    display:none;
}

.link{
    text-decoration: underline;
}

.link:hover{
    cursor: pointer;
}

.terms-container{
    background: burlywood;
    padding:0.1em;
    border-radius: 0.5em;
    letter-spacing: 2px;
    text-decoration: underline;
    color:rgb(65, 62, 62);
    margin-top:2em;
    min-width:220px;
}

.terms-container:hover{
    background: rgba(222, 184, 135, 0.671);
    letter-spacing: 3px;
    cursor: pointer;
}
.standard-info-container{
    padding:1em;
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
  }

.estimate-month{
    display: flex;
    justify-content: flex-start;
  }
.estimate-month-price{
    font-size: large;
}

.egenandel{
    margin-top:0em;
}

.ref-dato{
    margin-bottom:0em;
    margin-top:0em;
    font-size: small;
    color:#555555;
}

.contact-us-container{
    background: lightgray;
    margin-bottom: 20px;
    padding:15px;
    border-radius:1em;
}

.phone-container{
    display: flex;
    justify-content: space-around;
    margin-left:0;
    margin-bottom: 0;
    color:black;
    margin-left:auto;
    margin-right:auto;
    width:35%;
}


.phone-container p{
    border-bottom: 2px solid burlywood;
    font-weight: 800;
}

.main-info{
    display: flex;
    justify-content: space-between;
    margin-right:2em;
    margin-top:2em;
}

.icon-image{
    height: 20px;
    display:flex;
    align-items: center;
}

.image-index{
    background: cornsilk;
    margin-bottom:-30px;
    margin-right: 0px;
    width: 40px;
    padding: 5px;
}
.image-index-container{
    align-self: flex-start;
}

.image-carousel{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-left:1em;
    margin-right:1em;
}

.controls-size-all-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background: green;
    height:55vh;
}

.carousel-controls-all{
    width:30px;
    margin-left:-30px;
    margin-right:0px;
    align-self: flex-start;
}
.carousel-controls-size{
    width:30px;
    margin-left:-30px;
    margin-right:0px;
    align-self: flex-end;
}

.control-btn-image{
    background: none;
    border:none;
}

.control-btn-image:hover{
    background: lightgray;
    border-radius: 5px;
}



.county-map{
    visibility: hidden;
    height:0px;
}

.image-btn-prev{
    background:burlywood;
    border-radius: 50%;
    border: none;
    padding:0.3em;
    opacity: 0.8;
    margin-right:-4.3em;
    transition: all 0.3s ease-out;
}

.image-btn-prev:hover{
    cursor: pointer;
    border:0.1px solid black; 
}


.image-btn-next{
    background:burlywood;
    border-radius: 50%;
    border: none;
    padding:0.3em;
    opacity: 0.8;
    margin-left:0;
    transition: all 0.3s ease-out;
}

.image-btn-next:hover{
    cursor: pointer;
    border:0.1px solid black;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }
  
  /* CSS for the modal content */
  .modal-content {
    background: burlywood;
    border-radius: 1em;
    width: 80%;
    height: 80%;
    padding: 2em;
    text-align: center;
    animation: zoom 0.5s both; /* Use the zoom animation */
    transform-origin: center center; /* Zoom from the center */
    transform: scale(0.5); /* Start with 50% scale */
    opacity: 0;
    overflow-y: scroll;
    box-shadow: 10px 10px;

  }

  .modal-text div{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-text li {
      text-align: left;
  }

  .modal-text div p{
    display:flex;
    justify-content: flex-start; /* Aligns the content inside the flex container */
    align-items: flex-start; 
    text-align: left;
  }



  .close-modal {
    background:grey;
    border-radius:50%;
    justify-content: center;
    position: sticky;
    top: 0px; /* Adjust as needed */
    display:flex;
    justify-self: center;
    margin-left:auto;
    align-self: center;
    font-weight:bold;
    color:white;
    width: fit-content; /* Adjust as needed */
    padding:0.2em 0.5em;
    z-index: 999; /* Ensure it's above other content */
  }

  .close-modal:hover{
      opacity: 0.8;
      cursor: pointer;
  }

  .open-street-map-container{
      display:none;
  }

  /* Define the zoom animation */
  @keyframes zoom {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0.5;
      transform: scale(0.98);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .object-image {
    animation: fadeIn 0.5s ease-in-out; /* Apply animation on each render */
  }


  /* Apply the visible class when the modal is open */
  .modal.open {
    opacity: 1;
    visibility: visible;
  }

  .post-contact{
    display: flex;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width:35%;
  }

  .post-contact a{
    text-decoration: none;
    color:black;
    border-bottom: 2px solid burlywood;
    font-weight:800;
  }

@media (min-width: 1001px) {

    .object-image{
        max-width:50vw;
    }

    .phone-container{
        width:90%;
    }

    .post-contact{
        width:90%;
    }

    .general-info-container {
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(20%, 1fr));
        gap: 16px;
      }

    .county-map{
        visibility: visible;
        height:200px;
    }

    .mobile-county-map{
        visibility: hidden;
        display:none;
    }

    .header{
        border-top-right-radius: 1em;
        display: flex;
        justify-content: space-between;
    }

    .object-container{
        display:flex;
        justify-content: flex-end;
    }
    .location-container{
        margin-top: 10px;
        width:30%;
    }
    .county-map{
        margin-bottom: 20px;
    }

    .link-term-container{
        flex-direction: column;
    }





  }

  @media (max-width: 1001px){

    

    .image-and-main-info-container{
        flex-direction: column;
    }

    .image-and-main-info-container div .main-info{
        padding-right:1em;
        padding-left:1em;
        margin-bottom:0.5em;
    }

    .image-and-main-info-container .image-carousel{
        width:90%;
        margin-bottom:0em;
    }

    .general-info-container-top{
        margin-top:0.2em;
        flex-direction:row;
    }
    
  }

  @media (max-width: 550px) {

    .general-info-container{
        padding:20px;
        gap:5px;
        font-weight: 600;
    }

    .phone-container{
        width:70%;
    }

    .post-contact{
        width:70%;
    }

    .carousel-controls-all{
        margin-top:2em;
    }
    .carousel-controls-size{
        margin-bottom:2em;
    }
    .object-image{
        padding:1em;  
    }
    .image-index-container{
        margin-right:-4.3em;
        z-index:1;
    }
    .image-btn-prev{
        margin-right:-2.1em;
    }
    
    .image-btn-next{
        margin-left:-4.3em;
    }

    .free-text-container{
        flex-direction: column-reverse;
    }
    .info-dropdown-container{
        width:90%;
        margin-bottom: 2em;
    }

  }
