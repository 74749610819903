.ad-header-container{
    display: flex;
    flex-direction: row;
}

.ad-header-container img{
    height:80px;
    width:auto;
    margin:0.2em;
    margin-right: 1em;
}

.click-fav-info-container{
    display: flex;
    justify-content: space-around;
}

.click-fav-info{
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.click-fav-info img{
    height: 20px;
    margin-right: 1em;
}

.test-epost{
    padding:1em;
    background:red;
    display: none;
}
.test-epost:hover{
    cursor: pointer;
}

.object-div{
    background:lightgray;
    padding:0.3em;
    margin:0.5em;
    display:flex;
    flex-direction: column;
}

.object-info-div{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.main-container-ads{
    display: flex;
}

.filter-container-ads{
    width:20%;
    min-width:100px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.ads-container{
    width:80%;
    min-height: 400px;
}

.object-status{
    background:rgb(146, 181, 216);
    padding:0.2em;
    border-radius:0.2em;
}

.object-title:hover{
    cursor: pointer;
}

.object-status:hover{
    cursor: pointer;
}

.status-options{
    background:rgb(226, 220, 220);
    padding:0.2em;
}

.status-info{
    background:rgb(226, 220, 220);
    padding:0.2em;
    display: flex;
}

.generell-info{
    width:60%;
}

.generell-info p{
    margin-top:0.2em;
    margin-bottom:0.2em;
}

.status-option{
    margin:0.2em;
}

.status-option:hover{
    cursor: pointer;
    background:burlywood;
}

.more-info-btn{
    background:burlywood;
    width:30%;
    margin-left:35%;
    border-radius:0.5em;
    font-weight: 300;
    margin-top:0;
}

.more-info-btn:hover{
    cursor: pointer;
    letter-spacing: 0.03em;
}

.konfidensiell-info{
    background:burlywood;
    width:50%;
}

.konfideensiell-info-titel{
    color:black;
    text-decoration: underline;
    font-weight: 600;
}
.comment-container{
    display: flex;
    flex-direction: row;
    padding: 0.5em;
}

.comment-container .more-info-btn{
    margin-left:0.5em;
    max-height: 3em;
    padding:0.2em;
}

.comment-time{
    text-decoration: underline;
    margin-bottom:0;
    color:rgb(87, 84, 84);
    font-size:0.8em;
}

.comment-info{
    margin-top:0;
}

.red-background {
    background-color: red;
  }

.edit-add-btn{
    background: rgb(111, 167, 111);
    width:fit-content;
    margin-left:auto;
    margin-right:auto;
    padding:0.3em;
    border-radius:1em;
}

.edit-add-btn:hover{
    cursor: pointer;
}

.modal-annonser{
    padding:0.5em;
    position:fixed;
    top:5em;
    left:5%;
    background:burlywood;
    width:90%;
    min-height:30%;
    max-height:70vh;
    border: 1px solid black;
    border-radius:1em;
    overflow-y: scroll;
}

.modal-annonser button{
    border:none;
    padding:1em;
    margin-left:3em;
    border-radius:1em;
}

.modal-annonser button:hover{
    cursor: pointer;
}

.save-edit-btn{
    background: rgb(111, 167, 111);
}

.modal-annonser div input{
    width:70%;
}

.modal-annonser div textarea{
    width:70%;
    margin:0.2em;
}

.modal-annonser div h4{
    width:15%;
}

.modal-annonser div{
    display:flex;
    flex-direction: row;
}

@media (max-width: 500px) {
    .ad-header-container img{
        height:50px;
    }

    .main-container-ads{
        display:flex;
        flex-direction: column;
    }

    .filter-container-ads{
        width:100%;
        display:grid;
        grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
        grid-template-rows: auto auto; /* The first two rows will be automatically sized */
        gap: 10px; 
    }

    .filter-container-ads p {
        grid-column: span 3; /* Span across all columns */
        margin: 0; /* Optional: to remove default paragraph margin */
    }
    
    .filter-container-ads div {
        grid-column: span 3; /* Ensures the text input spans all columns */
    }
    
    .filter-container-ads label {
        display: flex;
        align-items: center; /* Vertically center the label with the checkbox */
    }

    .ads-container{
        width:100%;
    }

    .status-info{
        display:flex;
        flex-direction: column;
    }

    .generell-info{
        width:100%;
    }

    .konfidensiell-info{
        width:100%;
    }

    .object-info-div{
        display:grid;
        grid-template-columns: repeat(2, 1fr); 
    }
    .object-info-div p{
        margin:0.3em;
    }

}