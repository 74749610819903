.saved-searches-container{
    display:flex;
    flex-direction: column;
    min-height:70vh;
    max-height:80vh;
}

.search-field {
    padding: 8px 13px;
    /* Remove max-width property */
    font-size: 1rem;
    line-height: 1.5em;
    color: #373D41;
    background-color: #FFF;
    background-clip: padding-box;
    border: 1px solid #C5C5C7;
    border-radius: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    flex-grow: 1;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 2em; 
}

.search-submit {
    background-color: #1a1a1a;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    white-space: normal;
    -webkit-transition: none;
    transition: none;
    cursor: pointer;
    outline: 0;
    padding: 12px 22px 11px;
    font-size: 1rem;
    line-height: 1.25rem;
    border-radius: 6px;
    border-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 3em; 
    margin-top:0.5em;
}

.search-form .input-group {
    text-align: center;
}

.search-form {
    padding-top:10em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.header{
    background:burlywood;
    padding:1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 0em;
    display: flex;
    justify-content: space-between;
    margin-top:25px;
}

.sub-info{
    margin-left:2em;
    margin-right:2em;
    font-weight: 300;
}

.saved-searches-list-element{
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.saved-searches-list-element button{
    border:none;
    margin-left:1em;
    height:20px;
    align-self: center;
    background: rgb(211, 43, 37);
    color:white;
}

.saved-searches-list-element button:hover{
    cursor: pointer;
    opacity: 0.8;
}