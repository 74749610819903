.header-info{
    background:burlywood;
    background-image:
                linear-gradient(45deg,
                    rgba(222, 184, 135,1) 100%,
                    rgb(255,255,255,0.5) 1%,
                    rgba(200, 200, 200, 0.4) 39%), 
                    url(
                    "../../imgs/construction_background.jpg");
    background-size: cover;
    background-position: 50% 30%;
    font-weight:700;
    text-decoration: underline;
    padding:1.5em;
    letter-spacing: 0.05em;
    display: flex; 
    margin-top:0;
}

.start-info{
    margin:2em;
    max-width: 800px;
}

.sub-header{
    color: burlywood;
    background:rgb(71, 67, 67);
    padding:0.5em;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 50%;
}

.call-to-action{
    font-size:1.5em;
    border-bottom: burlywood 2px solid;
    width: fit-content;
    margin-left: auto;
    margin-right:auto;
}

.sale-points-container{
    display: flex;
    justify-content: center;
}
.sale-points{
    margin:3em;
    max-width:800px;

}

.sale-point-title{
    letter-spacing: 0.05em;
    margin:0.5em;
}

.sale-point{
    background:burlywood;
    padding:1.5em;
    border-radius:1em;
    margin-bottom:1em;
    font-weight: 300;
    background: rgba(222, 184, 135,0.4);
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

.arrow-icon-right{
    width:40px;
    transform: scaleX(-1);
    margin-top: -20px;
    margin-right: -35px;
    float: right;
}

.arrow-icon-left{
    width:40px;
    margin-top: -20px;
    margin-left: -35px;
    float: left;
}

@media (min-width: 600px) {
    .header-info{
        background-image:
        linear-gradient(45deg,
            rgba(222, 184, 135,1) 50%,
            rgb(255,255,255,0.5) 1%,
            rgba(174, 179, 185, 0.418) 49%), 
            url(
            "../../imgs/construction_background.jpg");
    }
  }