.modal-consent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .modal-content-consent {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    color:white;
    width:100%;
    height:33vh;
    top: 67vh;
    left: 0;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-content-consent h2{
    border-bottom:burlywood 2px solid;
    margin:1em;
    margin-bottom:0; 
  }

  .modal-content-consent p{
    max-width:1200px;
    margin:1em;
  }

  .modal-actions-consent{
      display:flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-end;
  }
  
  .modal-actions-consent button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius:3px;
    font-weight:700;
    color:rgb(36, 36, 36);
  }

  .modal-actions-consent button:hover{
      cursor: pointer;
      opacity: 0.8;
  }

  .modal-actions-consent .positiv{
    background:burlywood;
  }

  .modal-actions-consent .negativ{
    background:lightgrey;
}