
.for-sale-header{
  background:burlywood;
  background-image:
                linear-gradient(45deg,
                    rgba(222, 184, 135,1) 50%,
                    rgb(255,255,255,0.5) 1%,
                    rgba(200, 200, 200, 0.4) 49%), 
                    url(
                    "../../imgs/construction_background.jpg");
  background-size: cover;
  background-position: 50% 30%;
  padding:1.5em;
  letter-spacing: 0.05em;
  display: flex; 
  margin-top:0;
}

.search-result-container .search-result{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(222, 184, 135, 0.856);
  padding:0.5em;
  margin:0.7em;
  border-radius:0.5em;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
  min-height:150px;
  z-index:2;
}

.search-result:hover{
  background: rgb(199, 158, 105);
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}

h3{
    margin-bottom: 0em;
}

.sort-filter-container{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right:1em;
}

.sort-filter-container p {
  margin:0;
  margin-right:0.5em;
}

.sort-filter-container select {
  padding: 0.1em;
  border-radius: 1em;
  background:rgba(211, 211, 211, 0.589);
}

.search-obj-title{
  font-size: 0.9em;
  margin-top:0;
  max-width:80%;
  border-bottom: 1px solid black;
}

.search-result-info-icon{
  width:auto;
  height:1em;
  margin-right:0.2em;
}

.object-sub-info-container{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight:400;
  margin-top:0.3em;
}

.object-sub-info-container div{
  display:flex;
  flex-direction: row;
  margin-bottom: 0.2em;
}

.object-sub-info-container p {
  margin-right:1em;
  margin-top:0em;
  margin-bottom:0;
}

.btn-toggle-search-form {
    position: fixed;
    left: 25px; /* Adjust the left position as needed */
    top: 230px; /* Adjust the top position as needed */
    border: none;
    padding: 0.3em;
    border-radius: 0.6em;
    z-index: 10;
    transition: left 0.3s ease-in-out;
    background:#87acdd;
  }
.btn-toggle-search-form p{
  margin-top:0;
  margin-bottom:0;
  font-size:0.8em;
}
  
.btn-toggle-search-form.open {
    left: 0px; /* Adjust the left position as needed */
    top: 180px; /* Adjust the top position as needed */
  }
  
.btn-toggle-search-form.closed {
    left: 250px; /* Adjust the left position as needed */
    top: 180px;
  }
  
.btn-toggle-search-form:hover {
    cursor: pointer;
    background:rgba(0,0,0,0.4);
  }

.search-form{
    padding: 0em;
    margin:0;
    min-height:85vh;
    text-align: left;
}


.search-form h4{
  margin-top:0.7em;
  margin-bottom:0.1em;
}

.search-form h4 {
  font-weight:600;
  letter-spacing: 0.05em;
  width:fit-content;
  border-bottom:1px solid burlywood;
}

.slider-container {
  position: relative;
  width: 90%;
  margin-top: 5px; /* Add margin to make space for slider handles */
  padding-bottom:30px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 90%;
  height: 2px;
  background: rgba(222, 184, 135,0.2);
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  pointer-events: none; /* Prevents the background slider from capturing clicks */
}

.slider-min {
  z-index: 2; /* Lower z-index for the min slider */
}

.slider-max {
  z-index: 3; /* Higher z-index for the max slider */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: burlywood;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: all; /* Allows the thumb to capture clicks */
  position: relative;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: burlywood;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: all; /* Allows the thumb to capture clicks */
  position: relative;
}

.slider-container .slider + .slider {
  margin-top: 7px; /* Add margin to create a gap between sliders */
}

.slider-textbox{
  display:flex;
  flex-direction: row;
}


/* Base styles for the checkbox */
.search-form input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
  accent-color: burlywood;
  background-color: white;
  border: 2px solid burlywood;
  border-radius:3px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
}

/* Hover effect */
.search-form input[type=checkbox]:hover {
  background-color: burlywood;
}

/* Checked state - hide checkmark and customize appearance */
.search-form input[type=checkbox]:checked::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 13px;
  height: 13px;
  border: 1px solid black;
  border-radius:2px;
  background-color: burlywood; /* Change background color when checked */
}

.additional-options-search-filter{
  display:flex;
  flex-direction: column;
  font-size:0.9em;
  margin-left:1.5em;
}

.search-form-text{
  max-width: 190px;
  padding:0.5em;
  border-radius: 0.4em;
}

.search-form-price{
  max-width: 80px;
}

.search-form-search-btn {
  background: rgb(37, 211, 37);
  border-radius: 9px;
  box-shadow: rgb(14, 80, 14) 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  width: auto; /* Adjusted width to be responsive */
  display: block; /* Ensures the button takes full width of its container */
  margin: 0 auto 1em; /* Center horizontally and add margin at the bottom */
  display:none;
}

.search-form-search-btn:hover{
  cursor: pointer;
  opacity: 0.8;
}


.search-form-types{
    display: flex;
    flex-direction: column;
    padding: 0.1em;
    align-items: flex-start;
    margin-left: 0%;
}

.search-place{
    margin-top:0em;
    margin-bottom:0em;
    font-size: 0.9em;
}

.search-ref{
  margin-top:-1.9em;
  margin-bottom:0.7em;
  font-size: 0.7em;
}

.search-price{
  margin-top:0.5em;
  margin-bottom:0;
  font-weight:500;
  font-size:0.9em;
}

.search-place{
  margin-top:0em;
  margin-bottom:2em;
  font-size: 0.8em;
}

.search-text{
  font-size: 13px;
}

.search-result .search-info-container{
  width:65%;
  text-align: left;
  margin-left:0.1em;
  padding-top:1em;
}

.search-result img{
  background-size: 20%;
  background-repeat: no-repeat;
  object-position: 50% 50%;
  
}

.county-search-list{
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    margin-left: 0%;
}

.form-container {
  position: fixed;
  top: 60px;
  left: 0%; /* Start offscreen to the right */
  width: 250px;
  height: 90vh;
  overflow-y:auto;
  background:lightgray;
  border: 2px solid black;
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  font-size:0.8em;
  border:none;
  display:flex;
  flex-direction: column;
  }
  
.form-container.closed {
  transform: translateX(-100%); /* Slide in from the right */
  
}

.form-container.open {
  transform: translateX(0); /* Slide out to the right */
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}
  
.img-for-sale{
  min-height:100px;
  background:url("../../imgs/loading.png");
  border-radius: 0em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  width: 40%;
  height: auto;
  margin-left: 0em;
  margin-right:0.5em;
  object-fit:cover;
}

.img-for-sale-tag{
  width: 70px;
  height: 20px;
  background:rgb(7, 109, 7);
  text-align: center;
  position: absolute;
  transform: rotate(-45deg);
  padding: 7px 0;
  margin-top:10px;
  margin-left:-10px;
  clip-path: polygon(22% 0%,80% 0%,100% 40%,100% 70%,100% 100%,0% 100%,0% 70%,0% 45%);
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  z-index: 1;
  opacity: 0.6;
}

.img-for-sale-tag p {
  color:white;
  margin-top:0px;
}

.search-results{
  margin-top:1em;
  padding-right:1em;
  min-height: 60vh;
}

.ingen-treff{
  margin-top:5em;
  height:50vh;
}

.filter-menu-arrow{
  width:20px;
  height:auto;
}

.search-result-container  .search-favourite {
    position: relative;
    margin-right:-13px;
    margin-bottom:-60px;
    z-index:1;
    content:'';
    outline:none;
    background-color: transparent;
    appearance: none;
    transition: outline 0.3s ease-in-out, outline-offset 0.3s ease-in-out;
  }

.search-favourite:hover{
  opacity: 1;
  cursor: pointer;
  outline: 2px solid grey; 
  outline-offset: 6px;
}

.search-result-container .search-favourite-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: -15px;
}

.search-favourite.menu-open {
  opacity: 0.2;
  pointer-events: none;
}

.filter-btn.menu-open {
  opacity: 0.2;
  pointer-events: none;
}

.search-favourite-icon {
  font-size: 16px;
  margin-right:15px;
  margin-left:0px;
  margin-bottom:-55px;
}

.search-favourite-icon:hover{
  background:red;
  padding:0.2em 0.3em;
  border-radius:50%;
}

.search-favourite-icon img{
  width:15px;
}

.search-favourite-icon.checked:before {
  content: "\1F4E9"; /* Mail symbol */
}

/* Hide heart symbol when checkbox is checked */
.search-favourite:checked + .search-favourite-icon:before {
  display: none;
}

.object-sub-info-container p{
  font-size:0.8em;
}

.form-container form{
    /* Reset margin and padding */
    margin: 0.2em;
    padding: 0.2em;
    /* Reset display */
    display: block;
    /* Reset width */
    width: auto;
    overflow-y: auto;

}

.search-results-container-filter-open{
  margin-left: 210px;
  max-width: 77%;
  transition: margin-left 0.5s, max-width 0.5s;
  display:none;
}

@media (min-width: 580px) {
  .for-sale-header{
    background-image:
    linear-gradient(45deg,
        rgba(222, 184, 135,1) 40%,
        rgb(255,255,255,0.5) 1%,
        rgba(174, 179, 185, 0.418) 59%), 
        url(
        "../../imgs/construction_background.jpg");
}

  .img-for-sale{
    border-radius: 0em;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    width: 40%;
    height: auto;
    margin-left: 0em;
  }

  .object-sub-info-container p{
    font-size:1em;
  }

  .object-sub-info-container{
    flex-direction: row;
  }

  .search-result .search-info-container{
    padding-top:0;
  }

  .search-obj-title{
    font-size: 1.1em;
    font-weight: 600;
    max-width:80%;
    border-bottom: 1px solid black;
  }

  .search-price{
    margin-top:0.5em;
    margin-bottom:0;
    font-weight:500;
    font-size:1.2em;
  }

  .search-results-container-filter-open{
    display: block;
  }

  

}

@media (min-width: 1101px) {

  .img-for-sale{
    width:40%;
  }

  .search-results-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5em; 
  }



  .object-sub-info-container p{
    font-size:0.9em;
  }
}
