.header-new-user{
    background:burlywood;
    background-image:
                linear-gradient(45deg,
                    rgba(222, 184, 135,1) 50%,
                    rgb(255,255,255,0.5) 1%,
                    rgba(200, 200, 200, 0.4) 49%), 
                    url(
                    "../../imgs/construction_background.jpg");
    background-size: cover;
    background-position: 50% 30%;
    font-weight:700;
    text-decoration: underline;
    padding:1.5em;
    letter-spacing: 0.05em;
    display:flex;
    margin-top:0;
}

.full-form-container{
    width:80%;
    margin-left:10%;
}

.newUser-form-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    background:burlywood;
    border-radius: 1em;
    padding:0.5em;
    margin:auto;
    margin-bottom:1em;
    max-width:700px;
    
}

.new-user-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    margin-bottom: 30px;
    min-width:100%;
}

.form-element{
    width:50%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    height:3.5em;
    margin-left:20%;
    margin-right:20%;
    align-items:center;
}

.form-element input {
    max-width:100%;
    padding:0.5em;
}

.form-element p{
    letter-spacing:0.05em;
    font-weight: 800;
    border-bottom: 2px solid black;
}

.input-new-user{
    margin: 1em;
    padding:0.8em;
    border-radius: 0.7em;
}

.input-new-user:focus{
    background:rgba(248, 246, 246, 0.966);
}

.new-user-info{
    margin-left: 0%;
    margin-right:0%;
}


.get-password-email{
    display: flex;
    flex-direction: column;
    margin-right: 0%;
    margin-left:0%;
    margin-bottom:3em;
}

.email-input-get-password{
    margin: 1em;
    padding:0.7em;
    border-radius: 0.7em;
}

.btn-get-password{
    padding:0.5em;
    border-radius: 2em;
    background: green;
    color:white;
    margin-bottom:2em;
}

.user-settings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2em;
    background:lightgray;
    border-radius: 2em;
    margin:2em;
}

.edit-settings-btn{
    margin:2em;
    padding:1em 3em;
    border-radius: 2em;
    background: chartreuse;
}

.edit-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.delete-settings-btn{
    color:white;
    margin:2em;
    padding:1em 3em;
    border-radius: 2em;
    background: crimson;
}

.delete-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.modal-container-terms{
    border-radius:1em;
    box-shadow:10px 10px;
    position:absolute;
    top:25%;
    right:25%;
    width:50%;
    background:lightgray;
    padding:1.5em;
    height:50vh;
    overflow-y: auto;
}

.modal-container-terms p {
    letter-spacing: 0;
    font-weight:400;
    border:none;
}

.modal-close-btn:hover{
    cursor: pointer;
}

.form-element span{
    text-decoration:underline;
}

.form-element span:hover{
    cursor: pointer;
    opacity: 0.9;
}

@media (max-width: 680px) {
    .form-element{
        margin-left:5%;
        margin-right:5%;
        width:90%;
    }
  }