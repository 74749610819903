.new-user-form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 30%;
    margin-left: auto;
    width:20%;
    margin-bottom:30px;
}

.form-element{
    display: flex;
    flex-direction: row;
}

.input-new-user{
    margin: 1em;
    padding:0.2em;
    border-radius: 0.7em;
}

.input-new-user:focus{
    background:rgba(240, 240, 240, 0.9);
}

.new-user-info{
    margin-left: 20%;
    margin-right:20%;
}


.get-password-email{
    display: flex;
    flex-direction: column;
    margin-right: 20%;
    margin-left:20%;
    margin-bottom:3em;
}

.email-input-get-password{
    margin: 1em;
    padding:0.7em;
    border-radius: 0.7em;
}

.btn-get-password{
    padding:0.5em;
    border-radius: 2em;
    background: green;
    color:white;
    margin-bottom:2em;
}

.user-settings{
    display:flex;
    justify-content: space-around;
    background: burlywood;
    border-radius: 1em;
    width:60%;
    max-width:600px;
    margin-left:auto;
    margin-right:auto;
    padding-top:1em;
    margin-bottom:2em;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.3);
}


.user-settings-organisasjon{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.user-org-setting-title{
    text-decoration: underline;
}

.user-settings-edit{
    display: flex;
    flex-direction: row;
}

.user-settings-edit-buttons{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.user-settings-edit-buttons button {
    margin:0.7em;
}

.user-settings span{
    font-weight: 700;
}

.edit-settings-btn{
    align-self: center;
    background: rgb(37, 211, 37);
    border-radius: 999px;
    box-shadow: rgb(14, 80, 14) 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    margin-bottom:1em;
}

.edit-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.delete-settings-btn{
    align-self: center;
    background: rgb(211, 43, 37);
    border-radius: 999px;
    box-shadow: rgb(80, 14, 14) 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    margin-bottom:1em;
}

.delete-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.delete-modal{
    width:30%;
    background:lightgrey;
    padding:1em;
    position: absolute;
    top:40%;
    left:35%;
    box-shadow: 10px 10px;
}