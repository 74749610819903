.header-qr{
    background:burlywood;
    padding:1.5em;
    letter-spacing: 0.05em;
    display: flex; 
    margin-top:0px;
}

.qr-img{
    max-height:90vh;
}