.header-ringeliste{
    background:burlywood;
    padding:1.5em;
    letter-spacing: 0.05em;
    display: flex; 
    margin-top:0;
}

.update-ringelist-btn{
    margin:0.2em;
    background:rgb(144, 238, 144);
    padding:0.1em;
    border-radius:0.5em;
    width:80%;
    margin-left:auto;
    margin-right:auto;
    margin-top:50px;
}

.complete-ringeliste-btn{
    margin:0.2em;
    background:rgb(51, 184, 51);
    padding:0.1em;
    border-radius:0.5em;
    width:80%;
    margin-left:auto;
    margin-right:auto;
    margin-top:50px;
}

.update-ringelist-btn:hover{
    cursor: pointer;
    background:rgba(144, 238, 144,0.7);
}

.complete-ringeliste-btn:hover{
    cursor: pointer;
    background:rgba(51, 184, 51, 0.7);
}

.filter-sub-menu{
   font-weight: 800; 
}

.call-regions{
    display:flex;
    flex-direction:column;
    padding:1em;
    align-items: flex-start;
}

.call-results{
    width:80%;
}

.callElement{
    background:lightgray;
    margin:0.2em;
    padding:0.1em;
    width:100%;
    min-height:60px;
}

.callElementHeader{
    display:flex;
}

.positive-style {
    background: green;
}
.neutral-style {
    background: grey;
}
.negative-style {
    background: rgb(235, 68, 68);
}
.no-answer-style {
    background: rgb(255, 240, 23);
}

.not-called-style{
    background:lightgrey;
}

.callElementHeader:hover{
    background:rgb(238, 238, 238);
    cursor: pointer;
}

.callElementNavn{
    width: 20%;
}
.callElementCounty{
    width: 15%;
}
.callElementKode{
    width: 15%;
}
.callElementKommune{
    width: 20%;
}
.callElementBeskrivelse{
    width: 30%;
}

.extra-info{
    background: burlywood;
    padding:1em;
}

.extra-info span{
    font-weight: 600;
}

.additional-checkboxes{
    display:flex;
    flex-direction: column;
    margin-left:2em;
}


.ringeliste-full-container{
    display: flex;
    flex-direction: row;
}

.iframe-kontaktlogg-container{
    display:flex;
    flex-direction: row;
}

.kontakt-logg{
    background: rgb(211, 157, 86);
    margin-left:2em;
    width:50%;
    border-radius:1em;
}

.sale-objects{
    padding:1em;
    background:rgba(128, 128, 128, 0.356);
    margin:0.3em;
}

.kontakt-logg-element{
    padding:0.2em;
}

.kontakt-logg-header{
    display:flex;
    justify-content: center;
    text-decoration: underline;
    margin-bottom:0;
}

.kontakt-logg-header p{
    margin-left:0.2em;
    margin-right: 0.2em;
}

.kontakt-logg-info{
    margin-top:0;
    text-align:left;
    padding-left:0.2em;
}

.submit-btn-kontaktlogg{
    max-width:200px;
    margin-left:auto;
    margin-right:auto;
    color:white;
    background: blue;
    border-radius: 1em;
    padding:1em;
}

.submit-btn-kontaktlogg.clickable {
    cursor: pointer;
    opacity: 1;
  }
  
  .submit-btn-kontaktlogg.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }



.respons-valg-meny{
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content: space-around;
}

.respons-meny-valg p {
    transition: all 0.3s ease;
}

.respons-valg-positiv{
    color:white;
    background: rgb(93, 206, 93);
    padding:1em 2em;
    border-radius: 0.5em;
}

.respons-valg-nøytral{
    color:white;
    background:lightgray;
    padding:1em 2em;
    border-radius: 0.5em;
}

.respons-valg-negativ{
    color:white;
    background:red;
    padding:1em 2em;
    border-radius: 0.5em;
}

.respons-valg-ikke-svar{
    color:black;
    background: rgb(255, 240, 23);
    padding:1em 2em;
    border-radius: 0.5em;
}

.respons-valg-meny p.selected {
    border: 2px solid black;
}

.respons-valg-booking{
    padding:1em 3em;
    max-width:200px;
    margin-left:auto;
    margin-right:auto;
    background:gold;
    border-radius: 0.5em;
}

.respons-valg-booking:hover{
    cursor: pointer;
    opacity:0.7;
}

.respons-valg-positiv:hover{
    cursor: pointer;
    opacity: 0.7;
}
.respons-valg-nøytral:hover{
    cursor: pointer;
    opacity: 0.7;
}
.respons-valg-negativ:hover{
    cursor: pointer;
    opacity: 0.7;
}
.respons-valg-ikke-svar:hover{
    cursor: pointer;
    opacity: 0.7;
}

.call-comment{
    padding:2em;
}

.top-additional-info-container{
    display:flex;
    flex-direction: row;
    justify-content:space-evenly;
    overflow-y:auto;
}

.top-additional-info-general{
    width:250px;
}

.additional-info-buttons-container{
    display:flex;
    flex-direction: row;
}

.sale-objects-for-customer{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}

.sale-objects-for-customer p{
    margin-right: 1em;
}

@media only screen and (max-width: 600px) {
    
    .callElementHeader{
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
        grid-template-rows: auto auto;
    }

    .callElementNavn{
        grid-column: span 4;
        width:100%;
    }

    .top-additional-info-container{
        display:flex;
        flex-direction: column;
        justify-content:space-evenly;
        overflow-y:auto;
    }

    .respons-valg-meny{
        max-width:100%;
        margin-left:10px;
        margin-right:auto;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .iframe-kontaktlogg-container{
        display: flex;
        flex-direction: column;
    }
    .call-comment-form textarea {
        width: calc(50 * 0.6ch); /* 20 columns width, 0.6ch is approximately the width of a character */
      }
    
    .kontakt-logg{
        width:90%;
    }

  }