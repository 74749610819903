.new-user-form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 30%;
    margin-left: auto;
    width:20%;
    margin-bottom:30px;
}

.form-element{
    display: flex;
    flex-direction: row;
}

input{
    margin: 0.5em;
    padding:0.4em;
    border-radius: 0.7em;
}

.input-new-user:focus{
    background:rgba(240, 240, 240, 0.9);
}

.new-user-info{
    margin-left: 20%;
    margin-right: 20%;
}


.get-password-email{
    display: flex;
    flex-direction: column;
    margin-right: 20%;
    margin-left:20%;
    margin-bottom:3em;
}

.email-input-get-password{
    margin: 2em;
    padding:0.7em;
    border-radius: 0.7em;
}

.btn-get-password{
    align-self: center;
    border-radius: 999px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 18px;
    border: 0;
    margin-bottom:5em;
}

h1{
    margin-top:10%;
    margin-bottom:30px;
}
.user-settings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2em;
    background:lightgray;
    border-radius: 2em;
    margin:2em;
}

.edit-settings-btn{
    margin:2em;
    padding:1em 3em;
    border-radius: 2em;
    background: chartreuse;
}

.edit-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.delete-settings-btn{
    color:white;
    margin:2em;
    padding:1em 3em;
    border-radius: 2em;
    background: crimson;
}

.delete-settings-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}